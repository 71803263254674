.polka-container {
  @import "planviewer-embed/mixins/mixins";
  @import "planviewer-embed/variables";
  @import "planviewer-embed/base/fonts";
  @import "planviewer-embed/base-layer-switcher";
  @import "planviewer-embed/dragger";
  @import "planviewer-embed/feature-editor";
  @import "planviewer-embed/layer-switcher";
  @import "planviewer-embed/measure-tooltip";
  @import "planviewer-embed/openlayers";
  @import "planviewer-embed/print_tool";
  @import "planviewer-embed/toolbox";

  @import "planviewer-embed/override/variables";
  @import "planviewer-embed/override/dragger";
  @import "planviewer-embed/override/base-layer-switcher";
  @import "planviewer-embed/override/toolbox";
  @import "planviewer-embed/override/layer-switcher";
  @import "planviewer-embed/override/feature_editor";
  @import "planviewer-embed/override/switcher_base";

  position: relative;
  height: 100%;
  overflow: hidden !important;
}