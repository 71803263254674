/*
  Scss variables to use for website and viewers.
 */

@use "sass:color";
@use "sass:math";

$white: #fff;
$pv-grijs: #ded9cb;
$pv-grey-border: #ddd;
$pv-grey-input-border: #ccc;
$pv-white-smoke: #f5f5f5;
$off-white: #eef5d9;
$pv-grey-table: #f9f9f9;
$pv-grijs-licht: #f2f0ea;
$pv-donkergrijs: #5d5b54;
$pv-input-placeholder: #999;
$pv-zeerlichtgroen: #f0f6da;
$pv-blauw: #172983;
$pv-light-blue: #5bc0de;
$pv-groen: #acce39;
$pv-dark-green: #5cb85c;
$light-olive-green: #a6c83d;
$pv-groen-licht: #d6e79c;
$pv-orange: #e69000;
$pv-red: #ff4f36;
$pv-dropdown-border-color: #e5e5e5;
$pv-white: #fff;
$pv-headerGrey: rgb(246 245 242 / 85%);
$pv-primaryActive: #dfd9cc; // TODO: merge with $pv-grijs?
$pv-footerGrey: #dfd9cc; // TODO: merge with $pv-grijs?
$pv-footerSocialBg: #f6f5f2;
$pv-gray-light: #777;
$pv-gray-lighter: #eee;
$pv-gray-dark: #333;
$spacer: 1rem;

// styling login/register subscribe block
$pv-loginblockheight: 305px;
$pv-loginblockheight-pin: ($pv-loginblockheight * 2 - 10);

// Home height
$home-height: 450px;

// Old Bootstrap 3 container sizes still used in files  TODO: Remove after changing all queries to custom query mixins
$container-tablet: 750px;
$container-sm: $container-tablet;
$container-desktop: 970px;
$container-md: $container-desktop;
$container-large-desktop: 1170px;
$container-lg: $container-large-desktop;

// Variables modular

// Font sizes content and headings.
$font-size-base: 16px;
$font-size-base-mobile: 15px;
$font-size-lg: 18px;
$font-size-sm: 14px;
$small-font-size: 0.875em; // for inline elements like <pre>, <code>, <small> moslty used in parent elements.

$main-font: "open sans", "Segoe UI", tahoma, sans-serif;
$secondary-font: "dm sans", "Segoe UI", tahoma, sans-serif;
$font-size-h1: 32px;
$font-size-h2: 28px;
$font-size-h3: 22px;
$font-size-h4: 20px;
$font-size-h5: 18px;
$font-size-h6: 18px;
$font-size-h1-mobile: 28px;
$font-size-h2-mobile: 24px;
$font-size-h3-mobile: 20px;
$font-size-h4-mobile: 18px;
$font-size-h5-mobile: 17px;
$font-size-h6-mobile: 17px;
$body-color: #232b2b;
$body-line-height: 1.5;

// Change fontawesome default font-path
$fa-font-path: "../fonts/fontawesome6";

// Breakpoints based on Bootstrap TODO: Make breakpoints like Bootstrap 5.
$pv-screen-xs: 480px;
$pv-screen-sm: 768px;
$pv-screen-md: 992px;
$pv-screen-lg: 1200px;

// Remove when all breakpoints working with mixin.
$pv-screen-xs-max: ($pv-screen-sm - 1);
$pv-screen-sm-max: ($pv-screen-md - 1);
$pv-screen-md-max: ($pv-screen-lg - 1);

// Custom breakpoints
$pv-screen-1024: 1024px;

// Variables for POLKA embed viewer
$polka-highlight-color: #99b3cc;
$polka-border-radius: 5px;
$polka-border-radius-small: 3px;
$polka-ol-widget-background-color: rgb(0 0 0 / 50%);
$polka-ol-widget-highlight-color: rgb(0 60 136);
