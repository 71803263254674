.button-link {
  background: none;
  border: none;
  color: $orange;
  cursor: pointer;
}

.button-link-selected {
  background: none;
  border: none;
  color: $orange;
  text-decoration: underline;
  cursor: pointer;
}