.draggerUp {
  position: absolute;
}

.dragger {
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.layerswitcher_operator {
  display: none;
}