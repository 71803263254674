.draggerBox{
  width:$feature-width;
  pointer-events: none;
  * {
    pointer-events: initial;
  }
  margin:0 0 0 $switcher-margin;
  border-radius: $switcher-radius;
  //padding:($switcher-padding*1.5) $switcher-padding $switcher-padding 0;
  padding:$switcher-margin 0 0 0;
  height: 100%;
  background:none;
  .toggleSlideIn, .toggleSlideOut{
    color:$orange;
    font-size:2em;
    background:$switcher-background;
  }
  &.draggerUp{
    animation: 0.2s linear 0s 1 slideOnIn;
  }
  &.dragger{
    overflow: visible;
    animation: 0.2s linear 0s 1 slideOnOut;
    .layer-info-window{
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  .layer-info-window{
    @include switcher-dropshadow;
    padding:$switcher-padding*1.5 $switcher-padding;
    overflow: scroll;
    width: auto;
    height:auto;
    background:$switcher-background;
    max-height:95%;
    max-width:300px;
    top:$switcher-margin;
    .content{
      height: auto;
      width:auto;
      position: relative;
      right:0;
      .map-eye-icon, .map-eye-icon-active{
        margin-right:$switcher-margin;
        font-size:1.4em;
      }
      .map-eye-icon-active{
        span{
          color:red;
        }
      }
      .properties-feature{
        padding:0;
        width:100%;
        box-shadow: none;
        h3{
          border:none;
          padding:0 0 0.2em;
          margin:0 0 0.4em;
          border-bottom: 1px solid $orange;
          background:none;
          color:$orange;
          font-size:1em;
        }
        ul{
          width:100%;
          font-size:0.8em;
          li{
            margin-bottom:0.3em;
            strong{
              width:40%;
              overflow: clip;
            }
            span{
              width:auto;
              max-width:120px;
            }
          }
        }
      }
      .infoBlockTextInfo{
        border-top: 1px solid $orange;
        padding-top:15px;
        font-size:0.8em;
        width:100%;
        margin:0;
        left:0;
        font-style:italic;
        color:darken($orange, 20);
        text-transform: lowercase;
        br{
          display: none;
        }
      }
    }
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: $switcher-margin;
}

::-webkit-scrollbar-thumb {
  background-color: lighten($orange, 20);
  border-radius: $switcher-margin;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: lighten($orange, 10);
}