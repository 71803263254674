@use "sass:color";

.toolbox-top {
  @extend %open-sans-medium;

  left: 0;
  padding: 0.25rem;
  position: fixed;
  top: 0;

  .group {
    display: inline-block;
    margin-right: 2px;

    .button,
    > div {
      border-radius: 0;
      border-right: 1px solid color.adjust($polka-highlight-color, $lightness: 5%);
      margin-right: 0;

      &:first-child {
        border-bottom-left-radius: $polka-border-radius;
        border-top-left-radius: $polka-border-radius;
        margin-right: 0;
      }

      &:last-child {
        border-bottom-right-radius: $polka-border-radius;
        border-right: none;
        border-top-right-radius: $polka-border-radius;
      }
    }
  }

  button {
    @extend %open-sans-medium;

    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 5%);
    color: $pv-blauw;
  }

  .in-progress-message {
    display: inline-block;
    background: $white;
    border-radius: $polka-border-radius;
    margin-right: 0.5rem;
    font-weight: bold;
    text-rendering: auto;
    border: 0;
    padding: 0.5rem;
    height: 2rem;
    font-size: to-rem(14px);
    position: relative;
    top: 5px;
    margin-top: -5px;
    box-sizing: border-box;
    vertical-align: center;

    &.hidden {
      display: none;
    }

    .label {
      text-align: center;
      position: relative;
      bottom: 3px;
    }

    &.in-progress .label.done {
      display: none;
    }

    &.done .label.in-progress {
      display: none;
    }
  }

  .search {
    display: inline-block;
    position: relative;

    .search-input {
      margin-bottom: 0.2rem;
      position: relative;

      .search-query {
        background: $white;
        border: 0;
        border-radius: 5px;
        box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.05);
        color: $pv-blauw;
        height: 15px;
        margin-right: 8px;
        padding: 0.5rem 70px 0.5rem 0.5rem;
        width: 200px;
      }

      .button.button-clear {
        border-radius: 0;
        height: 29px;
        padding: 0.5rem;
        position: absolute;
        right: 40px;
        top: 1px;
      }

      .button.button-search {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        box-shadow: none;
        height: 29px;
        position: absolute;
        right: 1px;
        top: 1px;
      }

    }
  }

  .search-results {
    position: absolute;
    top: 100%;
    background: $white;
    max-height: 300px;
    min-height: 150px;
    width: 278px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
  }

  .hidden {
    display: none;
  }
}

.buttonBar {
  top: 42px;

  @include media-size-down($pv-screen-1024) {
    top: 69px;
  }
}

.toolbox-sidebar {
  position: fixed;
  top: -2px;
  right: 0;
  padding: 0.25rem;

  @include media-size-down($pv-screen-1024) {
    top: unset;
    bottom: 16px;
  }

  .group {
    display: grid;

    .button {
      &:first-child {
        border-radius: $polka-border-radius $polka-border-radius 0 0;
        margin-bottom: 0;
        border-bottom: 1px solid color.adjust($white, $lightness: -25%);
      }

      &:last-child {
        border-radius: 0 0 $polka-border-radius $polka-border-radius;
      }

      border-radius: 0;
      margin: 0;
      width: 60px;
    }
  }

  button {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    .label {
      display: none;
    }
  }
}

.expanded-tools {
  display: grid;

  .button {
    &:first-child {
      border-radius: 0 !important;
    }

    &:last-child {
      border-radius: 0 !important;
    }

    background-color: floralwhite;

    .active {
      background-color: $polka-highlight-color !important;
      cursor: default;
    }
  }
}

.additional-tools {
  display: block !important;
  position: relative;
  margin-bottom: -32px;
  right: 182px;
  margin-right: -120px;
}

.additional-tools-none {
  display: none !important;
  position: relative;
  margin-bottom: -32px;
  right: 182px;
  margin-right: -120px;
}

.plus {
  background-image: url("../images/plus_bg.png");
  background-repeat: no-repeat;
  background-position: 90% 10%;
}

.minus {
  background-image: url("../images/minus_bg.png");
  background-repeat: no-repeat;
  background-position: 90% 10%;
}

.button {
  position: relative;
  text-rendering: auto;
  font-weight: bold;
  border: 0;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  margin-right: 0.5rem;
  height: 2rem;
  cursor: pointer;
  border-radius: $polka-border-radius;
  background-color: $white;
  vertical-align: middle;

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: color.adjust($white, $lightness: -15%);
  }

  &:active,
  &.active {
    background-color: $polka-highlight-color;
    cursor: default;
  }

  .icon {
    position: absolute;
    left: 9px;
    top: 10px;
  }

  &.button-no-label {
    padding-right: 0;
  }
}

.toolbox-mini {
  button {
    @extend %open-sans-medium;

    border: 1px solid color.adjust($white, $lightness: -10%);
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 5%);
    color: $pv-blauw;
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-bottom: 0.2rem;

    .label {
      display: none;
    }
  }
}

.center {
  @include media-size-up($pv-screen-1024) {
    left: 40% !important;
  }
}

.options {
  width: 400px;
  height: 142px;
  padding: 10px;
  background: color.adjust($pv-grijs-licht, $lightness: 5%, $alpha: 0.8%);
  border-radius: $polka-border-radius;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 5%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
  max-height: 100%;
  overflow: hidden;

  @include media-size-down($pv-screen-1024) {
    height: 160px;
  }

  .inner {
    background: color.adjust($pv-grijs-licht, $lightness: 5%, $alpha: 0.8%);
    padding: 10px;

    table {
      width: 100%;

      td {
        width: 50%;

        .btn {
          @extend %open-sans-regular;

          border-radius: 2px;
          box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 50%);
          background-color: azure;
          color: #172983;
          width: 70px;
          cursor: pointer;
        }
      }
    }
  }

  .bold {
    font-size: to-rem(16px);
  }
}

.infoOptions {
  width: 400px;
  min-height: 30px;
  padding: 10px;
  background: color.adjust($pv-grijs-licht, $lightness: 5%, $alpha: 0.8%);
  border-radius: $polka-border-radius;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 5%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  margin: auto;
  max-width: 90%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-size-down($pv-screen-1024) {
    top: 0;
    bottom: 0;
  }

  i {
    color: #172983;
  }

  .button {
    @extend %open-sans-medium;

    border-radius: 0;
    background-color: floralwhite;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 5%);
    color: $pv-blauw;
    display: inline-block;
    padding: 0.5rem 1rem;

    .sup {
      font-size: to-rem(12px);
      position: relative;
      top: -12px;
      left: 0;
    }
  }

  .close {
    position: relative;
    width: 10px;
    height: 50px;
    margin-bottom: -50px;
    border-radius: 2px;
    top: -10px;
    right: 0;
    cursor: pointer;
    float: right;
  }
}

.in-progress-message {
  width: 400px;
  height: 142px;
  padding: 10px;
  background: color.adjust($pv-grijs-licht, $lightness: 5%, $alpha: 0.8%);
  border-radius: $polka-border-radius;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 5%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
  max-height: 100%;
  overflow: auto;
}

.mobileDisplay {
  @include media-size-up($pv-screen-1024) {
    display: none;
  }
}

.desktopDisplay {
  @include media-size-down($pv-screen-1024) {
    display: none;
  }
}
