.layer-switcher {
  @extend %open-sans-medium;

  border-left: 0;
  color: $pv-blauw;
  height: 100%;

  // position: absolute;
  right: 0;
  top: 0;
  width: 360px;

  @media (min-width: 1200px) {
    width: 332px;
  }

  @include media-size-between($pv-screen-1024, $pv-screen-lg) {
    right: -28px !important;
  }

  @media (min-width: 1200px) {
    width: 332px;
  }

  @include media-size-down($pv-screen-1024) {
    overflow-y: auto;
    top: 0%;
    width: 100%;
    z-index: 1000;

    .title {
      display: none;
    }
  }

  z-index: 0;
  overflow: hidden;

  > .title {
    background: fade(#fff, 80%);
    border-bottom: 1px solid $pv-grey-input-border;
    height: 48px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;

    h2 {
      margin: 0;
      font-size: to-rem(15px);
      padding: 12px (48px + 8px) 12px 8px;
      font-weight: bold;
      text-align: center;
    }

    .btn-close {
      background-color: #fff;
      border: 0;
      color: inherit;

      @media only screen and (max-width: 768px) {
        font-size: to-rem(20px);
        font-weight: bold;
      }

      height: 100%;
      left: 0;

      @media only screen and (max-width: 768px) {
        margin: 3px 0 -3px;
      }

      padding: 0 15px;
      position: absolute;
      top: 0;

      .label {
        display: none;
      }

      .icon {
        transform: scale(1.5);
        position: relative;
        top: 2px;

        &::before {
          font-size: to-rem(20px);
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $pv-grijs-licht;
      }
    }
  }

  > .content {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    height: 100%;

    @include media-size-down($pv-screen-1024) {
      max-height: 264px;
    }

    @include media-size-up($pv-screen-1024) {
      width: 332px;
      background: rgb(252 251 250 / 88%);
    }
  }

  .layers {
    box-sizing: border-box;
    margin-bottom: 0;
    padding: 8px 16px;

    h3 {
      background-color: $pv-zeerlichtgroen;
      border-bottom: 1px solid $pv-groen-licht;
      font-size: to-rem($font-size-h5);
      margin: -8px -16px 8px;
      padding: 8px 16px;
    }

    ul {
      border: 0;
      box-shadow: none;
      clear: both;
      font-size: to-rem(14px);
      font-weight: 400;
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 6px;
        background: none;

        &:hover {
          cursor: pointer;
        }

        input {
          display: inline-block;
          position: relative;
          top: 2px;

          &:focus {
            outline: none;
          }

          &::-moz-focus-inner {
            border: 0;
          }
        }
      }
    }
  }

  .transparencySlider {
    input {
      width: 100%;
    }
  }

  .layer-input-label {
    cursor: pointer;
    margin-left: 5px;
  }
}
