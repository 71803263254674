.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid blue;
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute;
}

.ol-scale-line {
  border-radius: 4px;
  bottom: 1rem;
  right: 5rem;
  padding: 2px;
  position: fixed;

  .ol-scale-line-inner {
    border: 1px solid #222;
    border-top: none;
    color: #222;
    width: 95px;
    font-size: to-rem(10px);
    text-align: center;
    margin: 1px;
    will-change: contents, width;
  }
}

.ol-overlay-container {
  will-change: left, right, top, bottom;
}

.ol-unsupported {
  display: none;
}

.ol-viewport .ol-unselectable {
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.ol-rotate {
  top: 0.5em;
  right: 0.5em;
  transition: opacity 0.25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: 0.5em;
}

.ol-full-screen {
  right: 0.5em;
  top: 0.5em;
}

@media print {
  .ol-control {
    display: none;
  }
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  display: block;
  font-weight: normal;
  font-size: 1.2em;
  will-change: transform;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-attribution {
  text-align: right;
  bottom: 0.5em;
  right: 0.5em;
  max-width: calc(100% - 1.3em);
}

.ol-attribution ul {
  margin: 0;
  padding: 0 0.5em;
  font-size: 0.7rem;
  line-height: 1.375em;
  color: #000;
  text-shadow: 0 0 2px #fff;
}

.ol-attribution li {
  display: inline;
  list-style: none;
  line-height: inherit;
}

.ol-attribution li:not(:last-child)::after {
  content: " ";
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}

.ol-attribution ul,
.ol-attribution button {
  display: inline-block;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution.ol-logo-only ul {
  display: block;
}

.ol-attribution:not(.ol-collapsed) {
  background: fade-out($polka-ol-widget-background-color, 0.2%);
}

.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
  height: 1.1em;
  line-height: 1em;
}

.ol-attribution.ol-logo-only {
  background: transparent;
  bottom: 0.4em;
  height: 1.1em;
  line-height: 1em;
}

.ol-attribution.ol-uncollapsible img {
  margin-top: -0.2em;
  max-height: 1.6em;
}

.ol-attribution.ol-logo-only button,
.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  top: 4.5em;
  left: 0.5em;
  height: 200px;
}

.ol-zoomslider button {
  position: relative;
  height: 10px;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}

.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block;
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 1px;
  left: 2px;
  position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: fade-out($polka-ol-widget-background-color, 0.2%);
}

.ol-overviewmap-box {
  border: 2px dotted fade-out($polka-ol-widget-highlight-color, 0.3%);
}
