.SwitcherSlide {
  @include media-size-up($pv-screen-1024) {
    left: 330px;
    animation: 1s ease-in 0s 1 slideSwitcherOnIn;
  }
}

.SwitcherBase {
  left: 0;

  @include media-size-up($pv-screen-1024) {
    animation: 1s ease-in 0s 1 slideSwitcherOnOut;
  }
}

.base-layer-switcher-control-container {
  position: fixed;
  margin-bottom: 1rem;
  margin-left: 1rem;
  bottom: 0;
  height: 100px;
  width: 100px;

  .base-layer-switcher {
    display: block;
    cursor: pointer;
    width: 100px;
    height: 100px;
    position: relative;
    background: white;

    img {
      width: 97px;
      height: 97px;
      border: white 3px solid;
      box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    }

    .gradient {
      position: absolute;
      left: 3px;
      bottom: 0;
      background: linear-gradient(to top, rgb(0 0 0 / 100%), rgb(0 0 0 / 30%), rgb(0 0 0 / 0%));
      width: 97px;
      height: 97px;
    }

    .title {
      position: absolute;
      color: white;
      bottom: 0;
      left: 8px;
      width: 97px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-shadow: black;
    }

    &:hover {
      .gradient {
        opacity: 0.5;
      }

      .title {
        opacity: 0.5;
      }
    }
  }
}
