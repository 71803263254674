.new-project{
  .form{
    label{
      display: none;
    }
  }
}

.project-index{
  h3{
    font-size:1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.project-index-header{
  display: flex;
  justify-content: space-between;
}


.edit-project{
  form{
    .form-group{
      label{
        display: none;
      }
    }
  }
}