html {
  height: calc(100% - 56px);
  body {
    height: 100%;
    .page-content {
      height: 100%;
      .new-project, .edit-project {
        height: 100%;
        .iframe-container {
          height: 100%;
        }
      }
    }
  }
}
.mapsapiframe{
  height:100%;
}
.iframediv{
  height: calc(100vh - 150px);
}
.order-iframe{
  height:calc(100vh - 65px);
}