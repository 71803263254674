body{
  font-family: Roboto!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image:url(../images/bg.png);
  background-position: left top;
  background-repeat: no-repeat;
  color:$text-color;
}
.top-menu{
}

h1{
  //text-transform: capitalize;
  // border-bottom:2px solid $orange;
}
h2{
  color:$orange;
}

.env-label {
  background-color: $brand-secondary;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  color: #FFFFFF;
  padding: 5px 5px 5px 5px;
  border-radius: 0 0 0.2rem 0.2rem;
  z-index: 9999;
}