@keyframes slideOnUp {
  0% {
    height: 20px;
  }

  100% {
    height: 60%;
  }
}

@keyframes slideOnDown {
  0% {
    height: 60%;
  }

  100% {
    height: 20px;
  }
}

@keyframes slideOnIn {
  0% {
    left: -320px;
  }

  100% {
    left: 0;
  }
}

@keyframes slideOnOut {
  0% {
    left: 0;
  }

  100% {
    left: -320px;
  }
}

@keyframes slideSwitcherOnOut {
  0% {
    left: 320px;
  }

  100% {
    left: 0;
  }
}

@keyframes slideSwitcherOnIn {
  0% {
    left: 0;
  }

  100% {
    left: 320px;
  }
}

.dragger {
  background: rgb(252 251 250 / 80%);
  position: fixed;
  z-index: 1;
  padding: 0 1px;

  @include media-size-down($pv-screen-1024) {
    animation: 1s ease-in 0s 1 slideOnDown;
    bottom: 0;
    width: calc(100% - 0px);
    height: 24px;
  }

  @include media-size-up($pv-screen-1024) {
    animation: 1s ease-in 0s 1 slideOnOut;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 320px;
    left: -320px;
  }
}

.draggerUp {
  position: fixed;
  background: rgb(252 251 250 / 88%);
  z-index: 1;

  @include media-size-down($pv-screen-1024) {
    animation: 1s ease-in 0s 1 slideOnUp;
    bottom: 0;
    height: 60%;
    width: calc(100% - 0px);
  }

  @include media-size-up($pv-screen-1024) {
    animation: 1s ease-in 0s 1 slideOnIn;
    top: 0;
    left: 0;
    height: 100%;
    width: 320px;
  }
}

.draggerBox {
  .search {
    top: 0;
    width: 100%;
    position: absolute;
    z-index: 999;

    @include media-size-down($pv-screen-1024) {
      position: static;
      margin: 5px 0 20px;
    }

    .search-input {
      position: relative;
      overflow: hidden;

      input {
        height: 34px;
        width: 100%;
        border: 0;
        padding-left: 15px;
      }

      .button {
        position: absolute;
        top: 0;
        padding: 5px;
        margin: 0;
        border-radius: 0;
        width: 32px;
        height: 36px;

        .fa-solid {
          font-size: to-rem(16px);
        }
      }

      .button-search {
        right: 0;
      }

      .button-clear {
        right: 32px;

        .fa-solid {
          font-size: to-rem(18px);
        }
      }

      .button-search,
      .button-clear {
        color: $pv-blauw;
      }

      @include media-size-down($pv-screen-lg) {
        margin: 0 15px;
      }
    }

    .search-result {
      background-color: white;
      z-index: 9003;
      position: relative;
      margin-bottom: -300px;
      height: 400px;

      &.hidden {
        display: none;
      }

      .overlay-loading {
        margin-top: 16px;
      }

      ul {
        list-style: none;

        li {
          a {
            text-decoration: none;
            font-size: to-rem($font-size-sm);
            color: black;
          }
        }

        li:hover {
          background-color: floralwhite;
        }
      }
    }
  }
}

.toggleUpDown {
  display: block;
  cursor: pointer;
  width: 100%;
  font-size: to-rem(20px);
  color: $pv-blauw;

  @include media-size-up($pv-screen-1024) {
    display: none;
  }

  .fa {
    color: $pv-blauw;
    font-size: to-rem(20px);
  }
}

.toggleSlideIn {
  display: block;
  position: absolute;
  right: -40px;
  background: rgb(252 251 250 / 80%);
  width: 40px;
  font-size: to-rem(22px);
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  color: #172983;
  z-index: 9000;

  @include media-size-down($pv-screen-1024) {
    display: none;
  }
}

.layerswitcher_operator {
  display: block;
  position: absolute;
  right: 332px;
  background: rgb(252 251 250 / 80%);
  width: 40px;
  font-size: to-rem(22px);
  border-radius: 6px 0 0 6px;
  cursor: pointer;
  top: 0;
  height: 36px;
  color: #172983;

  @include media-size-down($pv-screen-1024) {
    display: none;
  }
}

.blockTabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;

  .tab {
    font-weight: 500;
    font-family: $secondary-font;
    text-align: center;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 5%);
    color: $pv-blauw;
    cursor: pointer;
    border-radius: $polka-border-radius;
    background-color: #fff;
    flex: 0 0 calc(50% - 5px);
    max-width: calc(50% - 5px);
    padding: 6px;
  }

  @include media-size-up($pv-screen-1024) {
    display: none;
  }
}

.infoBlockTextTool {
  color: #172983;
  clear: both;
  display: block;

  @include media-size-up($pv-screen-1024) {
    padding: 10px;
  }
}

.blockHideInfo {
  display: none;
}

@media (max-width: 1024px) {
  .layer-switcher,
  .layer-info-window {
    &.hide-layer {
      display: none;
    }
  }

  .layer-switcher {
    padding-top: 3px;
  }
}
