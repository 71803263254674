// Open sans light
@font-face {
  font-family: "open sans";
  font-weight: 300;
  font-display: swap;
  src:
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-300.woff2") format("woff2"),
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-300.woff") format("woff");
}

// Open sans regular
@font-face {
  font-family: "open sans";
  font-weight: 400;
  font-display: swap;
  src:
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-regular.woff2") format("woff2"),
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-regular.woff") format("woff");
}

// Open sans less bold
@font-face {
  font-family: "open sans";
  font-weight: 500;
  font-display: swap;
  src:
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-500.woff2") format("woff2"),
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-500.woff") format("woff");
}

// Open sans semi bold
@font-face {
  font-family: "open sans";
  font-weight: 600;
  font-display: swap;
  src:
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-600.woff2") format("woff2"),
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-600.woff") format("woff");
}

// Open sans bold
@font-face {
  font-family: "open sans";
  font-weight: 700;
  font-display: swap;
  src:
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-700.woff2") format("woff2"),
    url("../fonts/open-sans/open-sans-v29-latin-ext_latin-700.woff") format("woff");
}

// Dm sans regular
@font-face {
  font-family: "dm sans";
  font-weight: 400;
  font-display: swap;
  src:
    url("../fonts/dm-sans/dm-sans-v11-latin-ext_latin-regular.woff2") format("woff2"),
    url("../fonts/dm-sans/dm-sans-v11-latin-ext_latin-regular.woff") format("woff");
}

// Dm sans semi bold
@font-face {
  font-family: "dm sans";
  font-weight: 500;
  font-display: swap;
  src:
    url("../fonts/dm-sans/dm-sans-v11-latin-ext_latin-500.woff2") format("woff2"),
    url("../fonts/dm-sans/dm-sans-v11-latin-ext_latin-500.woff") format("woff");
}

%open-sans-light {
  font-family: $main-font;
  font-weight: 300;
}

%open-sans-regular {
  font-family: $main-font;
  font-weight: 400;
}

%open-sans-medium {
  font-family: $main-font;
  font-weight: 500;
}

%open-sans-semi-bold {
  font-family: $main-font;
  font-weight: 600;
}

%open-sans-bold {
  font-family: $main-font;
  font-weight: 700;
}
%dm-sans-regular {
  font-family: $secondary-font;
  font-weight: 400;
}

%dm-sans-medium {
  font-family: $secondary-font;
  font-weight: 500;
}
