.SwitcherBase{
  animation: none;
  left: auto;
  right:$switcher-margin;
  a.SwitchBase{
  }
  a.SwitcherSlide{
    left: auto;
    right:$switcher-margin;
    animation: none;
  }
}
.base-layer-switcher-control-container{
  .base-layer-switcher{
    background: none;
    .title{
      bottom:6px;
    }
    .gradient{
      width:91px;
      bottom:6px;
    }
  }
}