// Media breakpoint mixin for min-width queries. But not 0.
@mixin media-size-up($size) {
  $size-min: if($size != 0, $size, null);

  @if $size-min {
    @media (min-width: $size-min) {
      @content;
    }
  }
}

// Media breakpoint mixin for max-width queries. -0.02 because of Safari rounding bug.
@mixin media-size-down($size) {
  $size-max: if($size and $size > 0, $size - 0.02, null);

  @if $size-max {
    @media (max-width: $size-max) {
      @content;
    }
  }
}

// Media breakpoint mixin for between two sizes. Include other mixins when one of them is null.
@mixin media-size-between($lower, $upper) {
  $size-min: if($lower != 0, $lower, null);
  $size-max: if($upper and $upper > 0, $upper - 0.02, null);

  @if $size-min != null and $size-max != null {
    @media (min-width: $size-min) and (max-width: $size-max) {
      @content;
    }
  } @else if $size-max == null {
    @include media-size-up($lower) {
      @content;
    }
  } @else if $size-min == null {
    @include media-size-down($upper) {
      @content;
    }
  }
}
