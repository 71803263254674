@use "sass:color";


.layer-switcher {
  @include switcher-dropshadow;
  font-family: Roboto;
  box-sizing: border-box;
  opacity: 0.4;
  transition: opacity 1s;
  transition-delay: 2s;
  height:auto;
  max-height:80%;
  overflow-y: scroll;
  overflow-x:hidden;
  padding:($switcher-padding*1.5) 0 $switcher-padding;
  padding:0;
  margin:$switcher-margin $switcher-margin $switcher-margin 0;
  background: $switcher-background;
  &:hover{
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 0s;
  }
  color: $text-color;
  width:$switcher-width;
  .content{
    //width:$switcher-width - $switcher-margin*2;
    width:100%;
    border-radius: $switcher-radius;
    padding:0;
    background:none;
    height:auto;
  }

  .layers {
    margin:$switcher-margin*2 $switcher-margin;
    padding:0;
    h3 { //Werkt niet
      border:none;
      padding:0 0 0.2em;
      margin:0 0 0.4em;
      border-bottom: 1px solid $orange;
      background:none;
      color:$orange;
      font-size:1em;
      //font-weight: bold;
      //&:after{
      //  content: " ";
      //  float:right;
      //  border: solid $orange;
      //  border-width: 0 2px 2px 0;
      //  display: inline-block;
      //  padding: 5px;
      //  transform: rotate(45deg);
      //  -webkit-transform: rotate(45deg);
      //}
    }
    &.transparencySlider{
      h3{
        display: none;
      }
    }
    ul{
      &#overlayList{
        font-size: 0.8em;
      }
      &#baseLayerList{
        font-size:0.8em;
        li{
          margin:0;
          label{
            margin:0 0 3px 5px;
            max-width:90%;
          }
        }
      }
    }
  }
  input[type="range"]{
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    background: lighten($brand-secondary,60);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    border-radius: 15px;
    transition: opacity .2s;
    &:hover{
      opacity: 1;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 30px;
      border-radius: 15px;
      height: 15px;
      background: $orange;
      cursor: pointer;
    }
    &::-moz-range-thumb {
      width: 30px; /* Set a specific slider handle width */
      height: 15px; /* Slider handle height */
      background: $orange; /* Green background */
      cursor: pointer; /* Cursor on hover */
      border:none;
    }
  }
}