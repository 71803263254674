.printForm {
  top: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 320px;
  border-radius: $polka-border-radius;

  @media all and (max-width: 394px) {
    top: 73px;
    max-width: calc(100% - 0.5rem);
  }
}

#print-tool {
  @extend %open-sans-medium;

  color: $pv-blauw;
  background-color: $pv-white-smoke;
}

#print-tool .print-label {
  font-size: to-rem(13px);
  font-weight: bold;
  flex: 0 1 30%;
  cursor: pointer;
}

#print-tool .print-input {
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%);
  color: $pv-blauw;
  font-size: to-rem(13px);
  line-height: 1.3333;
  padding: 5px;
  flex: 1;
}

#print-tool .print-input-radio {
  font-size: to-rem(13px);

  input {
    margin: 0 5px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

#print-tool .print-title {
  background-color: $pv-zeerlichtgroen;
  border-bottom: 1px solid $pv-groen-licht;
  font-size: to-rem(14px);
  font-weight: bold;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#print-tool .close-pdf-modal {
  cursor: pointer;
  font-size: to-rem(18px);

  &:hover {
    opacity: 0.7;
  }
}

#print-tool .print-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 8px;

  &:last-of-type {
    padding-bottom: 0;
  }
}

#print-tool .btn {
  background: $pv-blauw;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: to-rem(14px);
  font-weight: bold;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    background: #173fa7;
  }
}

form.form-pdf-print {
  padding: 10px;
}
