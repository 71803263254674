.toolbox-top {
  position: absolute;
  top: 7px;
  .group {
    button {
      padding: 0.3rem 1rem;
      &.button{
        border-radius: 0!important;
      }
      border: none;
      &:hover {
        background-color: $orange;
        color: lighten($orange, 30);
      }

      &:active, &.active {
        background-color: $orange;
        color: #FFF;
      }

      &.zoom_in-button {
        margin-right: 0;
      }

      &.zoom_out-button{
        margin-left: -5px;
      }
      &.measure_area-button {
        margin-left: -4px;
      }
    }
  }

  .search {
    .search-input {
      .search-query {
        padding-bottom: 1rem;
        padding-top: 1rem;
        width: 350px;
        border-radius: .25rem;
      }

      .button.button-search {
        .svg-inline--fa{
          display: block;
        }
      }

      .button.button-clear {
        line-height: 0.8rem;
        text-align: center;
        margin-right: 1.3rem;
      }
    }
  }
}

.toolbox-sidebar {
  position: absolute;
  top: -2px;
  right: 0;
  padding: 0.25rem;

  @include media-size-down($pv-screen-1024) {
    top: unset;
    bottom: 16px;
  }

  .group {
    display: grid;

    .button {
      &.button{
        border-radius: 0!important;
      }
      border: none;
      &:hover {
        background-color: $orange;
        color: lighten($orange, 30);
      }

      &:active, &.active {
        background-color: $orange;
        color: #FFF;
      }

      border-radius: 0;
      margin: 0;
      width: 60px;
    }
  }

  button {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    .label {
      display: none;
    }
  }
}

.expanded-tools {
  display: none;

  .button {
    &:first-child {
      border-radius: 0 !important;
    }

    &:last-child {
      border-radius: 0 !important;
    }

    background-color: floralwhite;

    .active {
      background-color: $polka-highlight-color !important;
      cursor: default;
    }
  }
}

.toolbox-mini {
  button {
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .center {
    left: 29% !important;
  }
}