.datatable-container {
  .row {
    .col-xs-12 {
      width: 100%;

      .dataTables_length {
        padding-bottom: 15px;
      }

    }

    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}