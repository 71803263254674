@use "sass:color";

.screenPositioning {
  position: absolute;

  @include media-size-down($pv-screen-1024) {
    position: relative;
  }
}

.edit-feature-window,
.layer-info-window {
  border-right: 0;
  height: 100%;
  overflow: hidden;
  top: 30px;
  width: 360px;
  z-index: 901;

  @media (min-width: 1200px) {
    width: 360px;
  }

  @include media-size-down($pv-screen-1024) {
    width: 100%;
    top: 0;
  }

  > .title {
    height: 48px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
    margin-bottom: -48px;

    @include media-size-down($pv-screen-1024) {
      display: none;
    }

    h2 {
      margin: 0;
      font-size: to-rem(15px);
      padding: 12px 8px;
      font-weight: bold;
      text-align: center;
    }

    .btn-close {
      background-color: #fff;
      border: 0;
      color: inherit;

      @media only screen and (max-width: 768px) {
        font-size: to-rem(20px);
        font-weight: bold;
      }

      height: 100%;

      @media only screen and (max-width: 768px) {
        margin: 2px 0 -2px;
      }

      padding: 0 15px;
      position: absolute;
      right: 12px;
      top: 0;

      .label {
        display: none;
      }

      .icon {
        transform: scale(1.5);
        position: relative;
        top: 2px;

        &::before {
          font-size: to-rem(20px);
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $pv-grijs-licht;
      }
    }
  }

  > .content {
    overflow: auto;
    padding: 0;
    position: absolute;
    right: 40px;
    height: 100%;
    overflow-x: hidden;
    width: 340px;
    padding-top: 4px;

    @include media-size-up($pv-screen-1024) {
      height: 99%;
    }

    @include media-size-down($pv-screen-1024) {
      max-height: 264px;
      right: 0;
      width: 100%;
    }

    ul.feature-properties-editable {
      margin: 0;
      list-style: none;
      padding: 8px 8px 0;

      .edit-field {
        border: 1px solid $pv-grey-input-border;
        border-top-left-radius: $polka-border-radius-small;
        border-top-right-radius: $polka-border-radius-small;
        margin-bottom: 8px;

        label {
          border-bottom: 1px solid $pv-grey-input-border;
          display: block;
          width: 100%;
          padding: 5px;
          font-weight: bold;
          font-size: to-rem(12px);
          box-sizing: border-box;
          background: color.adjust($pv-grijs-licht, $lightness: 5%);
          border-top-left-radius: $polka-border-radius-small;
          border-top-right-radius: $polka-border-radius-small;
        }

        .control {
          background: #fff;
        }

        &.field-string input {
          border: 0;
          border-radius: 0;
          background: #fff;
          width: 100%;
          margin: 0;
          padding: 8px 5px;
          box-sizing: border-box;
          font-size: to-rem(14px);
        }

        &.field-loose_enum {
          div.Select-control {
            border-radius: 0;
            border: 0;
            font-size: to-rem(14px);
            height: auto;

            .Select-placeholder,
            .Select-input {
              padding-left: 5px;
              padding-right: 5px;
              height: auto;
            }
          }

          .Select-menu-outer {
            border-radius: 0;

            .Select-option {
              font-size: to-rem(14px);

              &.is-focused {
                background-color: $polka-highlight-color;
              }

              &:last-child {
                border-radius: 0;
              }
            }
          }
        }
      }
    }

    .btn-delete {
      float: right;
      margin: 0 8px 15px;
      border-radius: 3px;
      border: 0;
      color: #fff;
      background-color: $pv-red;
      padding: 8px 12px;
      font-weight: bold;
      font-size: to-rem(14px);

      &:hover {
        background-color: color.adjust($pv-red, $lightness: -10%);
        cursor: pointer;
      }

      .label {
        position: relative;
        bottom: 1px;
      }

      .icon {
        position: relative;
        right: 2px;
      }
    }

    .properties-feature {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
      box-sizing: border-box;
      margin-bottom: 0;
      padding: 8px 16px 0 30px;

      @include media-size-down($pv-screen-1024) {
        padding: 8px 16px;
      }

      > h3 {
        background-color: $pv-zeerlichtgroen;
        border-bottom: 1px solid $pv-groen-licht;
        font-size: to-rem($font-size-h5);
        margin: -8px -16px 8px;
        padding: 8px 16px;
      }

      > ul {
        border: 0;
        box-shadow: none;
        clear: both;
        font-size: to-rem(13px);
        list-style: none;
        padding: 0;

        li {
          background: none;
          line-height: 1.4;
          margin-bottom: 2px;

          strong {
            display: inline-block;
            margin-right: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 40%;
            color: $pv-blauw;
          }

          span.value {
            display: inline-block;
            position: relative;
            vertical-align: top;
            width: 50%;
            overflow-wrap: break-word;
          }
        }
      }
    }

    .map-eye-li {
      width: 100%;
    }

    .map-eye-icon-active {
      float: right;

      span {
        color: $pv-blauw;
        font-size: to-rem(20px);
        margin-top: 4px;
        cursor: pointer;
      }
    }

    .map-eye-icon {
      float: right;

      span {
        color: color.adjust($pv-grey-input-border, $lightness: -20%);
        font-size: to-rem(20px);
        margin-top: 4px;
        cursor: pointer;
      }
    }

    .infoBlockTextInfo {
      margin: 14px 15px;

      p {
        margin: 0;
      }

      @include media-size-up($pv-screen-1024) {
        position: relative;
        left: 30px;
        margin-left: 0;
        margin-right: 0;
        max-width: 300px;
        padding-right: 5px;
      }
    }
  }

  > .featureContent {
    overflow: auto;
    padding: 0;
    position: absolute;
    height: 100%;
    top: 20px;
    overflow-x: hidden;
    width: 340px;
    padding-top: 4px;

    @include media-size-up($pv-screen-1024) {
      height: 99%;
    }

    @include media-size-down($pv-screen-1024) {
      max-height: 264px;
      right: 0;
      width: 100%;
    }

    .save_features-button {
      padding: 2px;
      color: #172983;
    }

    ul {
      list-style: none;
    }

    ul.feature-properties-editable {
      margin: 0;
      list-style: none;
      padding: 8px 8px 0;
      width: 90%;

      .edit-field {
        border: 1px solid $pv-grey-input-border;
        border-top-left-radius: $polka-border-radius-small;
        border-top-right-radius: $polka-border-radius-small;
        margin-bottom: 8px;

        label {
          border-bottom: 1px solid $pv-grey-input-border;
          display: block;
          width: 100%;
          padding: 5px;
          font-weight: bold;
          font-size: to-rem(12px);
          box-sizing: border-box;
          background: color.adjust($pv-grijs-licht, $lightness: 5%);
          border-top-left-radius: $polka-border-radius-small;
          border-top-right-radius: $polka-border-radius-small;
        }

        .control {
          background: #fff;
        }

        &.field-string input {
          border: 0;
          border-radius: 0;
          background: #fff;
          width: 100%;
          margin: 0;
          padding: 8px 5px;
          box-sizing: border-box;
          font-size: to-rem(14px);
        }

        &.field-loose_enum {
          div.Select-control {
            border-radius: 0;
            border: 0;
            font-size: to-rem(14px);
            height: auto;

            .Select-placeholder,
            .Select-input {
              padding-left: 5px;
              padding-right: 5px;
              height: auto;
            }
          }

          .Select-menu-outer {
            border-radius: 0;

            .Select-option {
              font-size: to-rem(14px);

              &.is-focused {
                background-color: $polka-highlight-color;
              }

              &:last-child {
                border-radius: 0;
              }
            }
          }
        }
      }
    }

    .btn-delete {
      float: right;
      margin: 0 25px 15px 8px;
      border-radius: 3px;
      border: 0;
      color: #fff;
      background-color: $pv-red;
      padding: 8px 12px;
      font-weight: bold;
      font-size: to-rem(14px);

      &:hover {
        background-color: color.adjust($pv-red, $lightness: -10%);
        cursor: pointer;
      }

      .label {
        position: relative;
        bottom: 1px;
      }

      .icon {
        position: relative;
        right: 2px;
      }
    }

    .properties-feature {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
      box-sizing: border-box;
      margin-bottom: 0;
      padding: 8px 16px 0 30px;

      @include media-size-down($pv-screen-1024) {
        padding: 8px 16px;
      }

      > h3 {
        background-color: $pv-zeerlichtgroen;
        border-bottom: 1px solid $pv-groen-licht;
        font-size: to-rem(14px);
        font-weight: bold;
        margin: -8px -16px 8px;
        padding: 8px 16px;
      }

      > ul {
        border: 0;
        box-shadow: none;
        clear: both;
        font-size: to-rem(13px);
        list-style: none;
        padding: 0;

        li {
          background: none;
          line-height: 1.4;

          strong {
            display: inline-block;
            margin-right: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 40%;
          }

          span.value {
            display: inline-block;
            position: relative;
            vertical-align: top;
            width: 50%;
            overflow-wrap: anywhere;
          }
        }
      }
    }

    .map-eye-li {
      width: 100%;
    }

    .map-eye-icon-active {
      float: right;

      span {
        color: $pv-blauw;
        font-size: 1.5em;
      }
    }

    .map-eye-icon {
      float: right;

      span {
        color: color.adjust($pv-grey-input-border, $lightness: -20%);
        font-size: 1.5em;
      }
    }
  }

  .layer-info-window {
    @include media-size-down($pv-screen-1024) {
      top: 0;
      width: 100%;
      overflow-y: auto;
      z-index: 1001;
      height: 100%;
    }
  }

  .edit-feature-window {
    position: fixed;

    ul {
      background: rgb(252 251 250 / 80%);
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
      clear: both;
      list-style-type: none;
      margin-top: -8px;
      padding: 8px 8px 0;

      li {
        background: none;

        strong {
          display: block;
          height: 100%;
          width: 40%;
        }

        span.value {
          height: 100%;
          position: relative;
        }
      }
    }
  }

  #map.editable-feature-selected,
  #map.viewable-feature-selected {
    .toolbox-top {
      left: 360px;
    }
  }

  .viewerBanner {
    img {
      max-width: 300px;
    }
  }

  .planSummery {
    font-size: to-rem(13px);
    line-height: 1;

    strong {
      color: $pv-blauw;
    }

    @include media-size-down($pv-screen-1024) {
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }

  .teksten {
    font-size: to-rem(13px);
    line-height: 1;
    position: relative;
    left: 6px;

    strong {
      font-size: to-rem($font-size-base);
      color: $pv-blauw;
      font-family: $secondary-font;
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
    }
  }

  .imro-plan {
    ul {
      li {
        list-style: none;
      }
    }

    .planName {
      font-family: $secondary-font;
      font-weight: 500;
      color: $pv-blauw;
      width: 260px;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 1.4em;
      overflow: hidden;
      display: inline-block;
      font-size: to-rem($font-size-lg);
      margin-bottom: 10px;

      @include media-size-down($pv-screen-sm) {
        font-size: to-rem($font-size-base);
      }
    }

    .plan-details {
      font-size: to-rem(13px);
      left: 6px;
      position: relative;
      width: 90%;
      margin-bottom: 10px;
      line-height: 1.2;

      &:last-of-type {
        margin-bottom: 2px;
      }

      strong {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: to-rem($font-size-sm);
        color: $pv-blauw;
      }

      .map-eye-icon {
        span {
          font-size: to-rem($font-size-base);
        }
      }
    }
  }

  .hrLine {
    width: 100%;
    background-color: #1abc9c;
    height: 1px;
  }

  .lodgings-block {
    margin-top: 10px;
    position: relative;

    .lodgings-toggler {
      font-family: $secondary-font;
      font-size: to-rem($font-size-h5);
      margin: 10px 0;
      font-weight: 500;
      color: $pv-blauw;

      .accordion-arrow {
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        color: $pv-orange;
        transition: color 0.2s ease-in-out;
      }
    }

    .lodgings-accordion-item {
      position: relative;
    }

    .checkbox-accordion,
    .checkbox-lodgings {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    .checkbox-accordion {
      &:checked {
        + .lodgings-accordion-toggler {
          .accordion-arrow {
            transform: rotate(90deg);
          }
        }

        ~ .lodgings-accordion-content {
          max-height: 100vh;
        }
      }
    }

    .checkbox-lodgings {
      &:checked {
        + .lodgings-toggler {
          .accordion-arrow {
            transform: rotate(0);
          }
        }

        ~ .lodgings-block-accordion {
          max-height: 0;
        }
      }
    }

    .lodgings-toggler,
    .lodgings-accordion-toggler {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    .lodgings-accordion-toggler {
      font-weight: 500;
      font-size: to-rem(13px);
      border-bottom: 1px solid $pv-grey-border;
      padding: 5px 2px 0;

      .accordion-arrow {
        transform: rotate(0);
        transition: transform 0.2s ease-in-out;
        color: $pv-blauw;
      }

      &:hover {
        color: $pv-orange;
        transition: color 0.2s ease-in-out;
      }
    }

    .lodgings-block-accordion,
    .lodgings-accordion-content {
      max-height: 0;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
    }

    .lodgings-block-accordion {
      max-height: 100vh;
    }

    .lodgings-accordion-content {
      ul {
        list-style-type: none;
        margin: 10px 0;
        padding: 0;

        li {
          display: flex;
          justify-content: space-between;

          strong,
          span {
            width: auto !important;
            word-wrap: normal !important;
          }

          strong {
            flex: 0 0 40%;
            max-width: 40%;
          }

          span {
            flex: 0 0 60%;
            max-width: 60%;
          }
        }
      }
    }
  }
}
