.tooltip-measure {
  position: relative;
  background-color: rgb(255 255 255 / 90%);
  border-radius: $polka-border-radius;
  font-weight: bold;
  color: #000;
  padding: 0.2rem 0.6rem;
  white-space: nowrap;
  z-index: 1000;

  &::before {
    border-top: 6px solid rgb(255 255 255 / 90%);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }
}
