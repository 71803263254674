
// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$orange: #EA6220;
// $selection: lighten(#0385ff,30);
$selection: #ffeddf;
// $brand-secondary: lighten(#102C83,20);
$brand-secondary: #102C83;
$theme-colors: (
        'primary': #EA6220
);
$text-color: #2F3993;

@import "../../bower_components/bootstrap/scss/bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@import "settings";
@import "base";
@import "header";
@import "footer";
@import "project";
@import "account";
@import "iframe";
@import "helpers";
@import "datatables";

@import "viewer/planviewer";