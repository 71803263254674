.account-form{
  form{
    @extend .form-inline;
    & *{
      width:100% !important;
    }
    input{
      @extend .form-control;
      @extend .mb-2;
      &[type="submit"]{
        @extend .btn;
        @extend .btn-success;
        @extend .btn;
      }
      &[type="checkbox"]{
        width:auto !important;
      }
    }
    label{
      &[for="remember_me"]{
        width:auto !important;
        @extend .ml-2;
      }
      //@extend .sr-only;
      justify-content: left;
    }
  }
}
.loggedout,
.loggedin{
  @extend .float-right;
  a{
    @extend .btn;
    @extend .btn-link;
    @extend .text-light;
    @extend .btn-sm;
    @extend .m-2;
    text-transform: capitalize;
  }

}